import { useEffect } from "react";

const defaultEvents = ["mousedown", "touchstart"];

export const useOnClickOutside = (ref, callback, events = defaultEvents) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      const elementExists = ref.current;

      if (!elementExists) return;

      const elementWasNotClicked = !ref.current.contains(event.target);

      if (elementWasNotClicked) {
        callback(event);
      }
    };

    events.forEach((event) => {
      document.addEventListener(event, handleClickOutside);
    });

    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, handleClickOutside);
      });
    };
  }, [ref, callback, events]);
};
